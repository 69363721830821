import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../services/API";
import type { RootState } from "../store";
import { endpoint } from "../endpoint";
import { formatErrors, handleAPI, handleImalipayAPI } from "@redux/services/APIHandler";

export type AuthState = {
  loading: boolean;
  username: string;
  mobile: string | null;
  access_token?: string | null;
  refresh_token?: string | null;
  wallet_access_token?: string | null;
  wallet_refresh_token?: string | null;
  message: string | null;
  branch: string | null;
  branch_id: string | null;
  sessionExpired: string | null;
  otp: number | null;
  retryOTP: number | null;
  step: number;
  errors: string[];
  profile: any;
  access_rights: any;
  profile_backup: any | null;
  agentImalipay: any | null
};

const initialState: AuthState = {
  loading: false,
  username: "",
  access_token: null,
  refresh_token: null,
  wallet_access_token: null,
  wallet_refresh_token: null,
  message: null,
  mobile: null,
  branch: null,
  branch_id: null,
  sessionExpired: null,
  otp: null,
  retryOTP: null,
  step: 1,
  errors: [],
  profile: null,
  access_rights: null,
  profile_backup: null,
  agentImalipay:  null

};

export const refreshToken = async (refresh_token: string) => {
  try {
    let success = null;
    let failure = null;
    await API.get({
      baseUrl: endpoint(),
      // baseUrl: "https://test.afx.accessforex.com/api/",
      endpoint: `auth/refresh`,
      token: refresh_token,
      success: (response: any) => {
        success = response.data;
      },
      failure: (error: any) => {
        failure = error.response.data;
      },
    });

    return {
      status: success ? "SUCCESS" : "ERROR",
      payload: success ?? failure,
    };
  } catch (e: any) {
    return {
      status: "ERROR",
      error: e.message,
      payload: null,
    };
  }
};

export const searchUsername = handleAPI(
  "auth/searchUsername",
  "get",
  `auth/username/:username`
);
export const verifyPassword = handleAPI(
  "auth/verifyPassword",
  "post",
  `auth/password`
);
export const requestOTP = handleAPI("auth/requestOTP", "get", `auth/otp`);
export const getProfile = handleAPI("auth/profile", "get", `global/profile`);
export const verifyOTP = handleAPI("auth/verifyOTP", "post", `auth/otp`);
export const confirmBranch = handleAPI(
  "auth/confirmBranch",
  "post",
  `auth/confirm-branch`
);


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // The PayloadAction type here is used to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      // state.value += action.payload;
    },
    restartAuth: (state, action: PayloadAction<string>) => {
      state.step = 1;
      state.loading = false;
      state.sessionExpired = action.payload ?? null;
      state.access_token = null;
      state.refresh_token = null;
      state.otp = null;
      state.retryOTP = null;
      state.mobile = null;
      state.message = null;
      state.username = "";
      state.profile = null;
      state.branch = null;
      state.branch_id = null;
      state.profile_backup = null;
      state.errors = [];
    },
    resetTokens: (state, action: PayloadAction<any>) => {
      // console.log("REFRESH PAYLOAD", action.payload);
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
      state.step = 1;
    },
    clearForm: (state) => {
      state.message = null;
      state.errors = [];
      state.sessionExpired = null;
    },
    Proceed: (state) => {
      state.profile = state.profile_backup;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchUsername.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.sessionExpired = null;
    });
    builder.addCase(searchUsername.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      state.step++;
    });
    builder.addCase(searchUsername.rejected, (state, { payload }: any) => {
      state.loading = false;

      if (payload.message) state.errors = [...state.errors, payload.message];
      if (payload?.errors?.error) state.errors = payload.errors.error;

      if (payload?.errors?.username)
        state.errors = [...state.errors, payload?.errors?.username];

      state.sessionExpired = null;
    });

    builder.addCase(verifyPassword.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.message = null;
    });
    builder.addCase(verifyPassword.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.wallet_access_token = payload.wallet_access_token;
      state.wallet_refresh_token = payload.wallet_refresh_token;

      state.message = payload.message;
      state.step++;
    });
    builder.addCase(verifyPassword.rejected, (state, { payload }: any) => {
      state.loading = false;

      if (payload.message) state.errors = [...state.errors, payload.message];

      if (payload?.errors?.error) state.errors = payload.errors.error;
      if (payload?.errors?.password)
        state.errors = [...state.errors, payload?.errors?.password];
    });

    builder.addCase(requestOTP.pending, (state) => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(requestOTP.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.otp = payload?.otp ?? null;
      state.retryOTP = payload?.unix ?? null;
      state.message = payload.message;
    });
    builder.addCase(requestOTP.rejected, (state, { payload }: any) => {
      state.loading = false;

      if (payload.message) state.errors = [...state.errors, payload.message];
      if (payload?.errors?.error) state.errors = payload.errors.error;

      if (payload?.errors?.otp)
        state.errors = [...state.errors, payload?.errors?.otp];
    });

    builder.addCase(verifyOTP.pending, (state) => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(verifyOTP.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      state.mobile = payload.user.mobile;
      state.wallet_access_token = payload.wallet_access_token;
      state.wallet_refresh_token = payload.wallet_refresh_token;
      state.profile_backup = payload.user;
      state.access_rights = payload.access_rights;
      state.step++;
      if (payload.branch) {
        state.branch = payload.branch;
        
      } else {
        state.profile = payload.user;
      }
    });
    builder.addCase(verifyOTP.rejected, (state, { payload }: any) => {
      state.loading = false;

      if (payload.message) state.errors = [...state.errors, payload.message];
      if (payload?.errors?.error) state.errors = payload.errors.error;

      if (payload?.errors?.otp)
        state.errors = [...state.errors, payload?.errors?.otp];
    });

    builder.addCase(confirmBranch.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.branch = null;
    });
    builder.addCase(confirmBranch.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload.message;
    });
    builder.addCase(confirmBranch.rejected, (state, { payload }: any) => {
      state.loading = false;

      if (payload.message) state.errors = [...state.errors, payload.message];
      if (payload?.errors?.error) state.errors = payload.errors.error;

      if (payload?.errors?.branch)
        state.errors = [...state.errors, payload?.errors?.branch];
    });

    // =================================================================

    // Get Profile
    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.branch = null;
      state.branch_id = null;
    });
    builder.addCase(getProfile.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.branch = payload?.branch ?? null;
      state.branch_id = payload?.branch_id ?? null;
      state.profile = payload.user;
      state.access_rights = payload.access_rights;
    });
    builder.addCase(getProfile.rejected, (state, { payload }: any) => {
      state.loading = false;
      if (payload?.errors?.error) state.errors = payload.errors.error;
    });
  },
});

//Export actions
export const {
  incrementByAmount,
  restartAuth,
  resetTokens,
  clearForm,
  setUsername,
  Proceed,
} = authSlice.actions;

// shortcuts
export const getToken = (state: RootState) => state.auth.access_token;

export default authSlice.reducer;
