import { handleAPI } from "@redux/services/APIHandler";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TSelectedRemitter = {
  id: string;
  name: string;
};

type ReferralType = {
  page: string;
  id: string;
};

export type globalState = {
  showModal: boolean;
  modalContent: any;
  currentPage: string;
  mobileMenuOpen: boolean;
  selectedRemitter: TSelectedRemitter | null;
  showSelectedRemitterDetails: boolean;
  referral: ReferralType | null;
  showConfirmDialog: boolean;
  rates: any | null;
  useLocalhost: boolean;
  ussdSessionId: string;
};

const initialState: globalState = {
  showModal: false,
  modalContent: null,
  currentPage: "",
  mobileMenuOpen: false,
  selectedRemitter: null,
  showSelectedRemitterDetails: false,
  referral: null,
  showConfirmDialog: false,
  rates: null,
  useLocalhost: false,
  ussdSessionId: "",
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    clearSelectedRemitter: (state) => {
      state.selectedRemitter = null;
    },

    setShowModal: (state, action: PayloadAction<boolean>) => {
      // console.log(action.payload);
      state.showModal = action.payload;
    },
    setModalContent: (state, action: PayloadAction<any>) => {
      // console.log(action.payload);
      state.modalContent = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<string>) => {
      state.currentPage = action.payload;
    },
    toggleMobileMenu: (state) => {
      state.mobileMenuOpen = !state.mobileMenuOpen;
    },
    setSelectedRemitter: (state, action: PayloadAction<TSelectedRemitter>) => {
      state.selectedRemitter = action.payload;
    },
    toggleSelectedRemitterDetails: (state, action: PayloadAction<boolean>) => {
      state.showSelectedRemitterDetails = action.payload;
    },
    setReferral: (state, action: PayloadAction<ReferralType>) => {
      state.referral = action.payload;
    },
    setShowConfirmationDialog: (state, action: PayloadAction<boolean>) => {
      state.showConfirmDialog = action.payload;
    },
    toggleLocalhost: (state) => {
      state.useLocalhost = !state.useLocalhost;
    },
    setUSSDSessionId: (state, action: PayloadAction<string>) => {
      state.ussdSessionId = action.payload;
    },
  },
});

//Export actions
export const {
  setShowModal,
  setModalContent,
  setCurrentPage,
  toggleMobileMenu,
  clearSelectedRemitter,
  setSelectedRemitter,
  toggleSelectedRemitterDetails,
  setReferral,
  setShowConfirmationDialog,
  toggleLocalhost,
  setUSSDSessionId,
} = globalSlice.actions;

export default globalSlice.reducer;
