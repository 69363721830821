import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

interface IProps {
  title: string;
  type: "primary" | "secondary" | "outline" | "accent" | "danger";
  //   onClick: React.MouseEventHandler<HTMLButtonElement>;
  // onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  submit?: boolean;
  block?: boolean;
  [x: string]: any; // => all other props (...rest)
}

const buttonType = (type: string): string => {
  switch (type) {
    case "primary":
      return "text-secondary bg-primary hover:bg-secondary hover:text-primary";
    case "secondary":
      return "text-primary bg-secondary hover:bg-primary hover:text-secondary";
    case "outline":
      return "text-primary border border-2 border-secondary hover:bg-secondary hover:text-primary";
    case "accent":
      return "bg-gray-300 hover:bg-gray-400 text-gray-800";
    case "danger":
      return "text-white bg-red-500 hover:bg-gray-400 hover:text-white";
    default:
      return "";
  }
};

export default function Button(props: IProps): JSX.Element {
  const { title, type, onClick, disabled, loading, submit, block, ...rest } = props;
  return (
    <button
      disabled={disabled}
      className={`rounded p-2 md:py-3 md:px-6 text-sm cursor-pointer ${
        block ? "w-full" : ""
      } font-semibold ${buttonType(type)} my-2 flex justify-center items-center`}
      onClick={onClick}
      type={submit ? "submit" : "button"}
      {...rest}
    >
      {loading ? <ClipLoader color={`${type === "accent" ? "#000" : "#fff"}`} size={20} loading={true} /> : title}
    </button>
  );
}
