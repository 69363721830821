import { formatErrors, handleAPI, handleImalipayAPI } from '@redux/services/APIHandler'
import { createSlice } from '@reduxjs/toolkit'

export type WalletState = {
  loading: boolean
  message: string | null
  errors: string[]
  details: any | null
  charges: any | null
  add: any | any
  search: any | null
  checkTransaction: any | null
  ledgerMovement: any | null
  confirm: any | null
  cancel: any | null
  duplicate: any | null
}

const initialState: WalletState = {
  loading: false,
  message: null,
  errors: [],
  details: null,
  charges: null,
  ledgerMovement: null,
  add: null,
  search: null,
  checkTransaction: null,
  confirm: null,
  cancel: null,
  duplicate: null,
}

export const createDeposit = handleAPI(
  'wallet/deposit/create',
  'post',
  'wallet/deposit',
)
export const searchWallet = handleAPI(
  'wallet/search',
  'post',
  'wallet/search/member/:mobile',
)
export const makeWithdrawal = handleImalipayAPI(
  'wallet/confirm/withdrawal',
  'ledgers/0.0.1/zw/wallet/transaction/temp/:transactionId',
  { method: "POST", data: "payload", contentType: "application/json" }
)
export const walletDuplicateCheck = handleAPI(
  'wallet/deposit/checkDuplicateDeposit',
  'post',
  'wallet/deposit/check/duplicate',
)
export const confirmWithdrawal = handleImalipayAPI("cashout", "ledgers/0.0.1/zw/wallet/transaction/temp/:transactionId", {method: "PUT",
 data: "otp", contentType: "application/json"})

export const searchCustomerWallet = handleImalipayAPI("search/customer",  `customers/0.0.1/zw/kyc/:mobile/profile`, { method: "GET", contentType: "application/json" })

export const ledgerMovements = handleImalipayAPI("ledgermovements", "ledgers/0.0.1/zw/wallet/transaction", {method: "POST", data: "ledgerPayload", contentType: "application/json"})


export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errors = []
    },
    clearDeposit: (state) => {
      state.loading = false
      state.errors = []
      state.add = null
    },
    clearWithdrawal: (state) => {
      state.loading = false
      state.errors = []
      state.checkTransaction = null
      state.duplicate = null
      
    },
    clearConfirm: (state) => {
      state.loading = false
      state.errors = []
      state.confirm = null
    },
    clearDuplicate: (state) => {
      state.loading = false
      state.errors = []
      state.duplicate = null
    },
    clearSearch: (state) => {
      state.loading = false
      state.message = null
      state.errors = []
      state.search = null
      state.duplicate = null
    },
    clearWallet: (state) => {
      state.loading = false
      // state.message = null;
      state.errors = []
      state.add = null
      state.search = null
      state.duplicate = null
      state.checkTransaction = null
      state.confirm = null
      state.duplicate = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createDeposit.pending, (state) => {
      state.loading = true
      state.errors = []
      state.message = null
    })
    builder.addCase(createDeposit.fulfilled, (state, { payload }: any) => {
      state.loading = false
      state.errors = []
      state.message = payload?.data
      state.add = payload
    })
    builder.addCase(createDeposit.rejected, (state, { payload }: any) => {
      state.loading = false
      state.errors = formatErrors(payload)
    })
    //=============================================================================================
    builder.addCase(searchCustomerWallet.pending, (state) => {
      state.loading = true
      state.errors = []
      state.message = null
      state.search = null
    })
    builder.addCase(searchCustomerWallet.fulfilled, (state, { payload }: any) => {
      state.loading = false
      state.errors = []
      state.message = "Wallet available for transacting"
      state.search = payload ?? null
    })
    builder.addCase(searchCustomerWallet.rejected, (state, { payload }: any) => {
      state.loading = false
      state.errors = formatErrors(payload)
    })

    //=============================================================================================
    builder.addCase(ledgerMovements.pending, (state) => {
      state.loading = true
      state.errors = []
      state.message = null
      state.ledgerMovement = null
    })
    builder.addCase(ledgerMovements.fulfilled, (state, { payload }: any) => {
      state.loading = false
      state.errors = []
      state.message = payload.statusMessage
      state.ledgerMovement = payload ?? null
    })
    builder.addCase(ledgerMovements.rejected, (state, { payload }: any) => {
      state.loading = false
      state.errors = formatErrors(payload)
    })

    // =============================================================================
    builder.addCase(makeWithdrawal.pending, (state) => {
      state.loading = true
      state.errors = []
      state.message = null
    })
    builder.addCase(makeWithdrawal.fulfilled, (state, { payload }: any) => {
      state.loading = false
      state.errors = []
      state.message = payload.statusMessage
      state.checkTransaction = payload ?? null
    })
    builder.addCase(makeWithdrawal.rejected, (state, { payload }: any) => {
      state.loading = false
      state.errors = formatErrors(payload)
    })
    // =============================================================================
    builder.addCase(confirmWithdrawal.pending, (state) => {
      state.loading = true
      state.errors = []
      state.message = null
    })
    builder.addCase(confirmWithdrawal.fulfilled, (state,  {payload} : any) => {
      state.loading = false
      state.errors = []
      state.message = payload?.statusMessage
      state.confirm = payload ?? null
    })
    builder.addCase(confirmWithdrawal.rejected, (state, { payload }: any) => {
      state.loading = false
      state.errors = formatErrors(payload)
    })

    // =============================================================================
    builder.addCase(walletDuplicateCheck.pending, (state) => {
      state.loading = true
      state.errors = []
      state.message = null
    })
    builder.addCase(
      walletDuplicateCheck.fulfilled,
      (state, { payload }: any) => {
        state.loading = false
        state.errors = []
        state.message = payload?.message
        state.duplicate = payload?.data ?? null
      },
    )
    builder.addCase(
      walletDuplicateCheck.rejected,
      (state, { payload }: any) => {
        state.loading = false
        state.errors = formatErrors(payload)
      },
    )
  },
})

//Export actions
export const {
  clearDeposit,
  clearSearch,
  clearWithdrawal,
  clearConfirm,
  clearWallet,
  clearDuplicate,
} = walletSlice.actions

export default walletSlice.reducer
