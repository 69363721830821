import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "@redux/services/API";
import { resetTokens, restartAuth, refreshToken } from "@redux/slices/authSlice";
import { endpoint, imalipayapi, remitone } from "@redux/endpoint";
import { APIType } from "./types";
import axios from "axios";


const handleURI = (payload: any, uri: string) => {
  const splitArray = uri.split("/");
  let finalResult: string = ``;
  splitArray.forEach((e: any) => {
    finalResult += e.startsWith(":") ? `${payload[e.substring(1)] ?? ""}/` : `${e}/`;
  });
  return finalResult.endsWith("/") ? finalResult.substring(0, finalResult.length - 1) : finalResult;

};


function parseXmlToJson(xml: any) {
  const json = {}
  for (const res of xml.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
    const key = res[1] || res[3]
    const value = res[2] && parseXmlToJson(res[2])
    //@ts-ignore
    json[key] = (value && Object.keys(value).length ? value : res[2]) || null
  }
  return json
}



export const  handleAPI = (name: string, type: string, uri: string, token: string | null = null, basic: boolean=false) => {
  return createAsyncThunk(name, async (payload: any, { getState, dispatch, rejectWithValue }) => {
    const currentState: any = getState();
    const access_token = currentState.auth.access_token;
    const refresh_token = currentState.auth.refresh_token;
    const useLocalhost = currentState.global.useLocalhost;


    try {
      let success = null;
      let failure = null;
      let responseCode = null;
      const basicAuth = `${'sheldon_access,  QWERTYU2'}`
      const apiPayload: APIType = {
        // baseUrl: useLocalhost ? "http://fbi.com:8000/api" : endpoint(),
        baseUrl: endpoint(), 
        endpoint: handleURI(payload, uri),
        token: `${basic ? 'Basic '+basicAuth: 'Bearer '}${token ?? access_token}`,
      
        // data: payload,
        success: (response: any) => {
          success = response.data;
        },
        failure: (error: any) => {
          failure = error.response.data;
          responseCode = error.response.status;
        },
        
      };

      if (type === "post" || type === "put") apiPayload.data = payload;

      type ObjectKey = keyof typeof API;
      const safeType = type as ObjectKey;
      await API[safeType](apiPayload);

      if (success) return success;
      // console.log(failure,responseCode);
      if (failure && responseCode === 401) {
        const refresh: any = await refreshToken(refresh_token);
        console.log(refresh);
        if (refresh.status === "SUCCESS") {
          dispatch(resetTokens(refresh.payload));

          // Make the API call again
          apiPayload.token = refresh.payload.access_token;
          await API[safeType](apiPayload);
          if (success) return success;
          else return rejectWithValue(failure);
        } else {
          // console.log("Session Expired! Please try again.")
          dispatch(restartAuth("Session Expired! Please try again."));
          return rejectWithValue(failure);
          // return rejectWithValue(failure);
        }
      } else {
        return rejectWithValue(failure);
      }
    } catch (e: any) {
      return rejectWithValue(e.message);
    }
  });
};



export const handleImalipayAPI = (name: string, url: string, options: any) => {
  return createAsyncThunk(
    name,
    async (arg: any, thunkAPI) => {
      const currentState: any = thunkAPI.getState();
      const access_token = currentState.iptoken.accessToken;

      try {
        const { method, headers, ...restOptions } = options // Destructure method

        const params = {...arg}

          const formattedUrl = handleURI(params, url); // Use data for URL formatting
          const response = await axios(`${imalipayapi()}${formattedUrl}`, {
            method,
            ...(method === "POST" || method === "PUT" ? { data: arg } : restOptions ), // Conditionally add data only for POST/PUT
            headers: {
              Authorization: `Bearer ${access_token}`,
              ...headers,
            },
          });

        if(response.headers["Content-Type"] === "application/xml"){
          const res: any = parseXmlToJson(response.data);
          if(res.status === "SUCCESS"){
            return res
          }else if(res.status === "FAIL"){
            return thunkAPI.rejectWithValue(res.message)
          }
        }
       
        if (response.data.statusCode === "9000") {
          console.log("dddd>>>", response.data.errors)
          return thunkAPI.rejectWithValue(response.data.errors);
        
        }  
        if (response.data.statusCode === "9007") {
          return thunkAPI.rejectWithValue(response.data.errors);
        
        }  
        return response.data;
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );
};



export function formatErrors(payload: any) {
  let errors: any = [];
  if (payload?.errors?.error)
    errors = Array.isArray(payload.errors.error) ? payload.errors.error : [payload.errors.error];
  else if (payload?.errors) {
    for (let err in payload.errors) {
      errors.push(payload.errors[err][0]);
    }
  }
  else if(payload) {
    errors.push(payload[0]);
  }
  console.log("pppp>>>", errors)
  return errors;
}

