import React from "react";
import { Link } from "react-router-dom";
import { Tracing } from "trace_events";

type TItem = {
  label: string;
  active: boolean;
  url: string;
};

interface IProps {
  items: TItem[];
}

export default function Tabs({ items }: IProps) {
  return (
    <div className="bg-gray-50 dark:bg-gray-800 my-2 mt-4 rounded-md overflow-hidden">
      <nav className="flex flex-col sm:flex-row">
        {items.map((item, key) => {
          return (
            <Link
              key={key}
              to={item.url}
              //   onClick={() => setSelected(country.id === 0 ? null : { id: country.id })}
              className={`py-4 px-6 block hover:text-primary dark:hover:text-secondary focus:outline-none text-primary dark:text-secondary ${
                item.active ? "border-b-2 border-primary dark:border-secondary font-medium" : ""
              }`}
            >
              {item.label}
            </Link>
          );
        })}
      </nav>
    </div>
  );
}
