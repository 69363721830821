import { handleImalipayAPI } from "@redux/services/APIHandler";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


 type tokenState = {
    loading?: boolean;
    error?: any;
    balance?: any | null;
    message?: string | null

}

const initialState: tokenState = {
  loading: false,
  error: [],
  balance: null,
  message: null
};

// export const getAccountNo = handleImalipayAPI("search/balance",  `/customers/0.0.1/zw/kyc/{mobile}/profile`, {method: "GET"})
  
export const getWalletBalance =  createAsyncThunk("search/walletBalance", async (balance: any, {rejectWithValue, getState}) => {
    const currentState: any = getState();
      const access_token = currentState.iptoken.accessToken;

    try {
      const rs = await axios.get(`${process.env.REACT_APP_IMALIPAY_DEV_LINK}ledgers/0.0.1/zw/wallet/account/${balance}/balance`,
       {
        headers: { 
            "Authorization": `Bearer ${access_token}`,
            "Content-Type": "application/json"
         }
      })
      if (rs.data.statusCode === "9000") {
        return rejectWithValue(rs.data.statusMessage)
      }
      return rs.data;
  
    } catch (e) {
 
      return rejectWithValue(e)
    }
  })


export const getWalletbalanceSlice = createSlice({
    name: "search/walletBalance",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getWalletBalance.pending, (state) => {
            state.loading = true;
            state.error = [];
        });
        builder.addCase(getWalletBalance.fulfilled, (state,{ payload}: any) => {
            state.loading = false;
            state.error=[];
            state.message = payload.statusMessage
            state.balance = payload.data?.[0];
        });
        builder.addCase(getWalletBalance.rejected, (state, {payload}: any) => {
            state.loading=false;
            state.error = payload
        })
    }
})

export default getWalletbalanceSlice.reducer