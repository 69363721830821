import { createSlice } from "@reduxjs/toolkit";
import { formatErrors, handleAPI } from "@redux/services/APIHandler";

export type PaymentsState = {
  loading: boolean;
  message: string | null;
  errors: string[];
  step: number;
  customerData: any | null;
  amountData: any | null;
  paymentsData: any;
  paymentDetails: any;
  transTypes: any[];
};

const initialState: PaymentsState = {
  loading: false,
  message: null,
  errors: [],
  step: 1,
  customerData: null,
  amountData: null,
  paymentsData: null,
  paymentDetails: null,
  transTypes: [],
};

export const searchAccount = handleAPI("payments/searchAccount", "post", `order/enquire`);
export const getPayments = handleAPI("payments/getPayments", "get", `reports/payments`);
export const getFilteredPayments = handleAPI("payments/getFilteredPayments", "post", `reports/filter`);
export const makePayment = handleAPI("payments/makePayment", "post", `order/pay`);
export const getPaymentDetails = handleAPI("payments/getPaymentDetails", "get", `reports/payments/:trans_ref`);
export const getTransTypes = handleAPI("payments/getTransTypes", "get", `reports/transtype`);

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    clearTransactions: (state) => {
      state.paymentsData = null;
    },
    clearForm: (state) => {
      state.message = null;
      state.errors = [];
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    setAccountNumber: (state, { payload }) => {
      state.step = payload;
    },
    cancelPayment: (state) => {
      state.message = null;
      state.errors = [];
      state.customerData = null;
      state.amountData = null;
      state.amountData = null;
      state.step = 1;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchAccount.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(searchAccount.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.amountData = payload?.response?.amountData ?? null;
      state.customerData = payload?.response?.customerData ?? null;
    });
    builder.addCase(searchAccount.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(makePayment.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(makePayment.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
    });
    builder.addCase(makePayment.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(getPayments.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(getPayments.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.paymentsData = payload?.response ?? null;
    });
    builder.addCase(getPayments.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    // Get Filtered Reports
    builder.addCase(getFilteredPayments.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.paymentsData = null;
    });
    builder.addCase(getFilteredPayments.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.paymentsData = payload?.response ?? null;
    });
    builder.addCase(getFilteredPayments.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(getPaymentDetails.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.paymentDetails = null;
    });
    builder.addCase(getPaymentDetails.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.paymentDetails = payload?.response ?? null;
    });
    builder.addCase(getPaymentDetails.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(getTransTypes.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(getTransTypes.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.transTypes = payload?.response ?? null;
    });
    builder.addCase(getTransTypes.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
  },
});

//Export actions
export const { clearForm, setStep, cancelPayment, clearTransactions } = paymentsSlice.actions;

export default paymentsSlice.reducer;
