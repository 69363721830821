import React from "react";
import { createRoot } from "react-dom/client";
// import ReactDOM from "react-dom";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import "./index.css";
import App from "./App";
import { Provider as ReduxProvider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider, Background, ErrorFallback } from "./components/app";

// Sentry.init({
//   dsn: "https://cf770868647d4f3fb1af9a1c18091b47@o1137430.ingest.sentry.io/6376699",
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

let container: any = null;

document.addEventListener("DOMContentLoaded", function (event) {
  if (!container) {
    container = document.getElementById("root");
    const root = createRoot(container!); // createRoot(container!) if you use TypeScript
    root.render(
      <React.StrictMode>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider>
                <Background>
                  <App />
                </Background>
              </ThemeProvider>
            </PersistGate>
          </ReduxProvider>
        </ErrorBoundary>
      </React.StrictMode>
    );
  }
});

