import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { formatErrors, handleAPI } from "@redux/services/APIHandler";

export type AuthState = {
  loading: boolean;
  message: string | null;
  branch: string | null;
  accountData: any;
  step: number;
  errors: string[];
  paymentMethods: any[];
};

const initialState: AuthState = {
  loading: false,
  message: null,
  branch: null,
  accountData: null,
  step: 1,
  errors: [],
  paymentMethods: [],
};

export const getPaymentMethods = handleAPI("global/getPaymentMethods", "get", `global/payment-methods`);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearForm: (state) => {
      state.message = null;
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    // Get Payment Methods
    builder.addCase(getPaymentMethods.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.paymentMethods = payload?.response ?? [];
    });
    builder.addCase(getPaymentMethods.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
  },
});

//Export actions
export const { clearForm } = dashboardSlice.actions;

// shortcuts
export const getBranch = (state: RootState) => state.dashboard.branch;

export default dashboardSlice.reducer;
