import { formatErrors, handleImalipayAPI } from "@redux/services/APIHandler";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


 type tokenState = {
    loading?: boolean;
    error?: any;
    message?: string | null;
    data?: any | null

}

const initialState: tokenState = {
  loading: false,
  message: null,
  error: [],
  data: null,
};

// export const getAccountNo = handleImalipayAPI("search/balance",  `/customers/0.0.1/zw/kyc/{mobile}/profile`, {method: "GET"})
  
export const registerAgentToImalipay =  createAsyncThunk("register/agent", async ({msisdn, payload}: any, {rejectWithValue, getState}) => {
    const currentState: any = getState();
      const access_token = currentState.iptoken.accessToken;

    try {
      const rs = await axios.post(`${process.env.REACT_APP_IMALIPAY_DEV_LINK}customers/0.0.1/zw/onboarding/${msisdn}/afx-flex`,
      payload,
       {
        headers: { 
            "Authorization": `Bearer ${access_token}`,
            "Content-Type": "application/json"
         }
      })
      if (rs.data.statusCode === "9000") {
        return rejectWithValue(rs.data.errors)
      }
      if (rs.data.statusCode === "9007") {
        return rejectWithValue(rs.data.errors) 
      }
      return rs.data;
  
    } catch (e) {
      return rejectWithValue(e)
    }
  })


export const registerAgentSlice = createSlice({
    name: "register/agent",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(registerAgentToImalipay.pending, (state) => {
            state.loading = true;
            state.error = [];
            state.message = null
        });
        builder.addCase(registerAgentToImalipay.fulfilled, (state,{ payload}: any) => {
            state.loading = false;
            state.message = payload.statusMessage
            state.data = payload;
        });
        builder.addCase(registerAgentToImalipay.rejected, (state, {payload}: any) => {
            state.loading=false;
            state.error = formatErrors(payload)
        })
    }
})

export default registerAgentSlice.reducer