import { createSlice } from "@reduxjs/toolkit";
import { formatErrors, handleAPI } from "@redux/services/APIHandler";

export type PaymentsState = {
  loading: boolean;
  message: string | null;
  errors: string[];
  remitters: any | null;
  beneficiaries: any | null;
  filteredBeneficiaries: any | null;
  transactions: any | null;
  limits: any | null;
  currency: any | null;
  details: any | null;
  createdRemitter: any | null;
  transactionReports: any | null;
};

const initialState: PaymentsState = {
  loading: false,
  message: null,
  errors: [],
  remitters: null,
  beneficiaries: null,
  filteredBeneficiaries: null,
  transactions: null,
  limits: null,
  currency: null,
  details: null,
  createdRemitter: null,
  transactionReports: null,
};

export const searchRemitter = handleAPI("remitter/search", "post", `remitter/search`);
export const getBeneficiaries = handleAPI("remitter/beneficiaries", "get", `beneficiary/list/:id`);
export const getFilteredBeneficiaries = handleAPI("remitter/beneficiaries/list", "get", `beneficiary/list/:id`);
export const getRemitterDetails = handleAPI("remitter/details", "get", `remitter/:id`);
export const getRemitterTransactions = handleAPI("remitter/transactions", "get", `remitter/list-trans/:id`);
export const getRemitterTransactionsReports = handleAPI("remitter/transactions/reports", "post", `/reports/remitter/transactions/:id`);
export const getRemitterLimits = handleAPI("limit/list", "get", `limit/list/:id`);
export const createRemitter = handleAPI("remitter/create", "post", `remitter/create`);
// export const getRemitterCurrency = handleAPI("/currencies/list", "get", `/currencies/list`);

export const remitterSlice = createSlice({
  name: "remitter",
  initialState,
  reducers: {
    clearSearch: (state) => {
      state.loading = false;
      state.message = null;
      state.errors = [];
      state.remitters = null;
      state.details = null;
      state.limits = null;
      state.beneficiaries = [];
    },
    clearCreatedRemitter: (state) => {
      state.createdRemitter = null;
    },
    clearRemitterTransactions: (state) => {
      state.errors = [];
      state.transactionReports = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchRemitter.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(searchRemitter.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.remitters = payload?.response ?? null;
    });
    builder.addCase(searchRemitter.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =============================================================================
    builder.addCase(getBeneficiaries.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.beneficiaries = null;
    });
    builder.addCase(getBeneficiaries.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.beneficiaries = payload?.response ?? null;
    });
    builder.addCase(getBeneficiaries.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =============================================================================
    builder.addCase(getFilteredBeneficiaries.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.filteredBeneficiaries = null;
    });
    builder.addCase(getFilteredBeneficiaries.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.filteredBeneficiaries = payload?.response ?? null;
    });
    builder.addCase(getFilteredBeneficiaries.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =============================================================================
    builder.addCase(getRemitterDetails.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.details = null;
    });
    builder.addCase(getRemitterDetails.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.details = payload?.response?.details ?? null;
    });
    builder.addCase(getRemitterDetails.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =============================================================================
    builder.addCase(getRemitterTransactions.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.details = null;
      state.transactions = null;
    });
    builder.addCase(getRemitterTransactions.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.transactions = payload?.response ?? null;
    });
    builder.addCase(getRemitterTransactions.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    //=================================================================================
    builder.addCase(getRemitterLimits.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.limits = null;
    });
    builder.addCase(getRemitterLimits.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.limits = payload?.response ?? null;
    });
    builder.addCase(getRemitterLimits.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    //=================================================================================
    builder.addCase(createRemitter.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.createdRemitter = null;
    });
    builder.addCase(createRemitter.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.createdRemitter = payload?.response ?? null;
    });
    builder.addCase(createRemitter.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    //=================================================================================
    builder.addCase(getRemitterTransactionsReports.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.transactionReports = null;
    });
    builder.addCase(getRemitterTransactionsReports.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.transactionReports = payload?.response?.data ?? null;
    });
    builder.addCase(getRemitterTransactionsReports.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
  },
});

//Export actions
export const { clearSearch, clearCreatedRemitter, clearRemitterTransactions } = remitterSlice.actions;

export default remitterSlice.reducer;
