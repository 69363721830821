import { createSlice } from "@reduxjs/toolkit";

import { formatErrors, handleAPI } from "@redux/services/APIHandler";

export type OutboundState = {
  loading: boolean;
  message: string | null;
  errors: string[];
  data: any;
  transactionDetails: any;
};

const initialState: OutboundState = {
  loading: false,
  message: null,
  errors: [],
  data: null,
  transactionDetails: null,
};

export const getWaitingList = handleAPI("outbound/getWaitingList", "get", `outbound/waiting-list`);
export const getErrorList = handleAPI("outbound/getErrorList", "get", `outbound/error-list`);
export const getProcessedList = handleAPI("outbound/getProcessedList", "get", `outbound/reports`);
export const getTransactionDetails = handleAPI(
  "outbound/getTransactionDetails",
  "get",
  `outbound/transaction-details/:trans_ref`
);
export const clearPayment = handleAPI("outbound/clearPayment", "post", `/outbound/process-transaction`);

export const outboundTransactionSlice = createSlice({
  name: "outbound",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWaitingList.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.data = null;
      state.transactionDetails = null;
    });
    builder.addCase(getWaitingList.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.data = payload?.response ?? null;
    });
    builder.addCase(getWaitingList.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(getErrorList.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.data = null;
      state.transactionDetails = null;
    });
    builder.addCase(getErrorList.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.data = payload?.response ?? null;
    });
    builder.addCase(getErrorList.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(getProcessedList.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.data = null;
      state.transactionDetails = null;
    });
    builder.addCase(getProcessedList.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.data = payload?.response ?? null;
    });
    builder.addCase(getProcessedList.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(getTransactionDetails.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.transactionDetails = null;
      state.data = null;
    });
    builder.addCase(getTransactionDetails.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.transactionDetails = payload?.response ?? null;
    });
    builder.addCase(getTransactionDetails.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    builder.addCase(clearPayment.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(clearPayment.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
    });
    builder.addCase(clearPayment.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
  },
});

//Export actions
export const {} = outboundTransactionSlice.actions;

export default outboundTransactionSlice.reducer;
