import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

 type tokenState = {
    loading?: boolean;
    error?: any;
    accessToken?: string | null

}

const initialState: tokenState = {
  loading: false,
  error: [],
  accessToken: null,
};

export const getToken = createAsyncThunk("token/Imalipay", async (creds: any, {rejectWithValue}) => {
    try {
      const rs = await axios.post("https://auth.development.imalipay.com/realms/APISIX/protocol/openid-connect/token", creds.toString(),
       {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      })
      if (rs.data.statusCode === "9000") {
        return rejectWithValue(rs.data.statusMessage)
      }
      return rs.data;
  
    } catch (e) {
      return rejectWithValue(e)
    }
  })
  


export const imalipayTokenSlice = createSlice({
    name: "token",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getToken.pending, (state) => {
            state.loading = true;
            state.error = [];
        });
        builder.addCase(getToken.fulfilled, (state,{ payload}: any) => {
            state.loading = false;
            state.error=[];
            state.accessToken = payload.access_token;
        });
        builder.addCase(getToken.rejected, (state, {payload}: any) => {
            state.loading=false;
            state.error = payload
        })
    }
})

export default imalipayTokenSlice.reducer