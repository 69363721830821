import React from "react";
import { BrowserRouter, Route, Routes as Switch, Navigate } from "react-router-dom";

import { ProtectedRoute } from "./components/app";

import { AppLoader } from "./components/layout";

const Home = React.lazy(() => import("./pages/Home"));

const LocalTransactionsReports = React.lazy(() => import("./pages/local-transactions/reports"));

const ExternalReports = React.lazy(() => import("./pages/transactions/components/reports"));

const LocalTransactionsClear = React.lazy(() => import("./pages/local-transactions/clear"));
const LocalTransactionDetails = React.lazy(() => import("./pages/local-transactions/details"));
const CreateTransaction = React.lazy(() => import("./pages/local-transactions/create"));

const TransactionDetails = React.lazy(() => import("./pages/transactions/details"));
// const Payments = React.lazy(() => import("./pages/Payments"));
// const Profile = React.lazy(() => import("./pages/Profile"));

const OutboundTransactions = React.lazy(() => import("./pages/outbound-transactions/transactions"));
const OutboundTransactionsDetails = React.lazy(() => import("./pages/outbound-transactions/details"));

const MamaMoneySearch = React.lazy(() => import("./pages/transactions/mamamoney/search"));
const SenditooSearch = React.lazy(() => import("./pages/transactions/senditoo/search"));

const Paytumi = React.lazy(() => import("./pages/transactions/paytumi"));

const SearchTransaction = React.lazy(() => import("./pages/transactions/search"));
const PayoutTransaction = React.lazy(() => import("./pages/transactions/payout"));

const SearchRemitter = React.lazy(() => import("./pages/remitters/search"));
const SearchBeneficiary = React.lazy(() => import("./pages/beneficiaries/search"));

// BENEFICIARY
const UpdateBeneficiary = React.lazy(() => import("./pages/beneficiaries/update"));
const CreateBeneficiary = React.lazy(() => import("./pages/beneficiaries/create"));
const BeneficiariesDetails = React.lazy(() => import("./pages/beneficiaries/details"));

// REPORTS
const AgentReport = React.lazy(() => import("./pages/Reports/AgentReport"));
const TerrapayReport = React.lazy(() => import("./pages/Reports/TerrapayReport"));
const RemitterReport = React.lazy(() => import("./pages/Reports/RemitterReport"));
const BeneficiaryReport = React.lazy(() => import("./pages/Reports/BeneficiaryReport"));
const PaytumiReport = React.lazy(() => import("./pages/Reports/PaytumiReport"));

// WALLETS 
const WalletDashboard = React.lazy(() => import("./pages/wallets/dashboard"));


// REMITTER
const RemitterDetails = React.lazy(() => import("./pages/remitters/details"));
const RemitterBeneficiaries = React.lazy(() => import("./pages/remitters/beneficiaries"));
const RemitterTransactions = React.lazy(() => import("./pages/remitters/transactions"));
const CreateRemitter = React.lazy(() => import("./pages/remitters/create"));

const RemitOne = React.lazy(() => import("./pages/RemitOne"));

const PaytumiReceipt = React.lazy(() => import("./pages/transactions/paytumi/receipt"));

const Receipt = React.lazy(() => import("./pages/Receipt"));
const PayAtReceipt = React.lazy(() => import("./pages/transactions/payat/Receipt"));
const ThirdPartyReceipt = React.lazy(() => import("./pages/transactions/components/receipt"));
const POP = React.lazy(() => import("./pages/POP"));

const Login = React.lazy(() => import("./pages/Login"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

const Branches = React.lazy(() => import("./pages/branches"));
const USSDSimulator = React.lazy(() => import("./pages/ussd"));

const UI = React.lazy(() => import("./pages/UI"));

const EL = (props: any) => {
  const { as: Component } = props;
  return <Component />;
};

const Routes = () => {
  const routes = [
    { component: Home, path: "/", protected: true },

    // local-transactions/clear - Clear Payment
    // local-transactions/RC - Payment Details

    { component: LocalTransactionsClear, path: "/local-transactions/clear", protected: true },
    { component: LocalTransactionsReports, path: "/local-transactions/reports", protected: true },
    { component: LocalTransactionDetails, path: "/local-transactions/:id", protected: true },
    { component: CreateTransaction, path: "/create-transaction", protected: true },

    { component: ExternalReports, path: "/transactions/:partner/reports", protected: true },

    { component: OutboundTransactions, path: "/outbound-transactions/:type", protected: true },
    { component: OutboundTransactionsDetails, path: "/outbound-transactions/:type/:id", protected: true },
    { component: RemitOne, path: "/remitone", protected: true },

    // REMITTER
    { component: SearchRemitter, path: "/remitters/search", protected: true },
    { component: RemitterBeneficiaries, path: "/remitters/:id/beneficiaries", protected: true },
    { component: RemitterTransactions, path: "/remitters/:id/transactions", protected: true },
    { component: RemitterDetails, path: "/remitters/:id", protected: true },
    { component: CreateRemitter, path: "/remitters/create", protected: true },

    // BENEFICIARY
    { component: SearchBeneficiary, path: "/beneficiaries/search", protected: true },
    { component: UpdateBeneficiary, path: "/beneficiaries/update/:id", protected: true },
    { component: CreateBeneficiary, path: "/beneficiaries/create", protected: true },
    { component: BeneficiariesDetails, path: "/beneficiaries/:id", protected: true },

    // REPORTS
    { component: AgentReport, path: "/reports/transaction/agent", protected: true },
    { component: TerrapayReport, path: "/reports/transaction/terrapay", protected: true },
    { component: RemitterReport, path: "/reports/transaction/remitter", protected: true },
    { component: BeneficiaryReport, path: "/reports/transaction/beneficiary", protected: true },
    { component: PaytumiReport, path: "/reports/transaction/paytumi", protected: true },

    // Transactions
    { component: MamaMoneySearch, path: "/transactions/mamamoney/search", protected: true },
    { component: SenditooSearch, path: "/transactions/senditoo/search", protected: true },

    { component: Paytumi, path: "/transactions/paytumi", protected: true },

    { component: SearchTransaction, path: "/transactions/search", protected: true },
    { component: PayoutTransaction, path: "/transactions/payout", protected: true },

    { component: TransactionDetails, path: "/transactions/:id", protected: true },

    { component: PaytumiReceipt, path: "/transactions/paytumi/:type/:ref", protected: true },

    { component: Receipt, path: "/receipt/:type/:ref", protected: true },
    { component: PayAtReceipt, path: "/order/payat/:type/:ref", protected: true },
    { component: ThirdPartyReceipt, path: "/transactions/:partner/:type/:ref", protected: true },
    { component: POP, path: "/pop/:ref", protected: true },

    { component: Login, path: "/login", protected: false },

    { component: Branches, path: "/branches", protected: true },
    { component: USSDSimulator, path: "/ussd", protected: true },

    { component: UI, path: "/ui", protected: false },


    // Wallet
    { component: WalletDashboard, path: "/wallets/dashboard", protected: true },
  ];

  // Fallback route
  routes.push({ component: NotFound, path: "*", protected: false });

  return (
    <React.Suspense fallback={<AppLoader />}>
      <BrowserRouter>
        <Switch>
          {routes.map((route, key) => {
            return route.protected ? (
              <Route
                key={key}
                path={route.path}
                element={
                  <ProtectedRoute>
                    <EL as={route.component} />{" "}
                  </ProtectedRoute>
                }
              />
            ) : (
              <Route key={key} path={route.path} element={<EL as={route.component} />} />
            );
          })}
        </Switch>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default Routes;
