import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

export default function AppLoader() {
  return (
    <div className="flex justify-center items-center bg-primary min-h-screen w-full">
      <PulseLoader color={"#fff"} loading={true} size={20} />
    </div>
  );
}
