import { createSlice } from "@reduxjs/toolkit";
import { formatErrors, handleAPI } from "@redux/services/APIHandler";

export type PaymentsState = {
  loading: boolean;
  message: string | null;
  errors: string[];
  rates: any | null;
  details: any | null;
  charges: any | null;
  add: any | any;
  search: any | null;
  check: any | null;
  confirm: any | null;
  cancel: any | null;
};

const initialState: PaymentsState = {
  loading: false,
  message: null,
  errors: [],
  rates: null,
  details: null,
  charges: null,
  add: null,
  search: null,
  check: null,
  confirm: null,
  cancel: null,
};

export const getTransactionRates = handleAPI("transaction/get-rate", "get", `transaction/get-rate`);
export const getTransactionCharges = handleAPI("transaction/get-charges", "post", `transaction/get-charges`);
export const createTransaction = handleAPI("transaction/create", "post", "transaction/add");
export const searchTransaction = handleAPI("transaction/search", "post", "transaction/search");
export const payoutCheckTransaction = handleAPI("transaction/check", "post", "transaction/payout/check");
export const payoutTransaction = handleAPI("transaction/confirm", "post", "transaction/payout/confirm");
export const cancelTransaction = handleAPI("transaction/cancel", "post", "transaction/cancel");

// transaction/details/RC062845003280

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    clearTransactionError: (state) => {
      state.errors = [];
      state.cancel = null;
    },
    clearRatesTransaction: (state) => {
      state.rates = null;
      state.errors = [];
    },
    clearChargesTransaction: (state) => {
      state.charges = null;
      state.errors = [];
    },
    clearSearch: (state) => {
      state.loading = false;
      state.message = null;
      state.errors = [];
      // state.rates = null;
      // state.charges = null;
      state.add = null;
      state.search = null;
      state.check = null;
      state.confirm = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactionRates.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(getTransactionRates.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.rates = payload?.response ?? null;
    });
    builder.addCase(getTransactionRates.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =============================================================================
    builder.addCase(getTransactionCharges.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(getTransactionCharges.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.charges = payload?.response ?? null;
    });
    builder.addCase(getTransactionCharges.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    //=============================================================================================
    builder.addCase(createTransaction.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(createTransaction.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.add = payload?.response ?? null;
    });
    builder.addCase(createTransaction.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    //=============================================================================================
    builder.addCase(searchTransaction.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.search = null;
    });
    builder.addCase(searchTransaction.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.search = payload?.response ?? null;
    });
    builder.addCase(searchTransaction.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    // =============================================================================
    builder.addCase(payoutCheckTransaction.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(payoutCheckTransaction.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.check = payload?.response ?? null;
    });
    builder.addCase(payoutCheckTransaction.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
    // =============================================================================
    builder.addCase(payoutTransaction.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
    });
    builder.addCase(payoutTransaction.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      // state.message = payload?.message ?? null;
      state.message = "Payout Processed Successfully";
      state.confirm = payload?.response ?? null;
    });
    builder.addCase(payoutTransaction.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });

    // =============================================================================
    builder.addCase(cancelTransaction.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.message = null;
      state.cancel = null;
    });
    builder.addCase(cancelTransaction.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.message = payload?.message ?? null;
      state.cancel = payload?.response ?? null;
    });
    builder.addCase(cancelTransaction.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.errors = formatErrors(payload);
    });
  },
});

//Export actions
export const { clearSearch, clearRatesTransaction, clearChargesTransaction, clearTransactionError } =
  transactionSlice.actions;

export default transactionSlice.reducer;
