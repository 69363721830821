import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Breadcrumbs from "./Breadcrumbs";
import { PageTitle } from "../../components/layout";
import { setCurrentPage } from "../../redux/slices/globalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import { IoDocumentLock } from "react-icons/io5";

import { useIdleTimer } from "react-idle-timer";
import { Button } from "@components/form-elements";

type TBreadcrumbs = {
  title?: string;
  parent: string;
  current: string;
};
interface IProps {
  title: string;
  children: React.ReactElement | JSX.Element[] | React.ReactNode;
  breadcrumbs?: TBreadcrumbs;
  active?: string;
  fluid?: boolean;
}

const timeout = 10_000;
const promptBeforeIdle = 4_000;

export default function PageContainer(props: IProps) {
  const { title: pageTitle, breadcrumbs, children, active, fluid } = props;

  const dispatch = useAppDispatch();

  const { mobileMenuOpen } = useAppSelector((state: any) => state.global);

  React.useEffect(() => {
    if (active) dispatch(setCurrentPage(active));
  }, [active]);

  const [state, setState] = useState<string>("Active");
  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    setState("Idle");
    setOpen(false);
  };

  const onActive = () => {
    setState("Active");
    setOpen(false);
  };

  const onPrompt = () => {
    setState("Prompted");
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? "seconds" : "second";

  return (
    <>
      <PageTitle title={pageTitle} />
      <div className="flex">
        <aside className={`h-screen sticky  ${mobileMenuOpen ? "w-full block" : "hidden lg:block"} top-0 z-20`}>
          <Sidebar />
        </aside>

        <main className={`p-6 flex-1 relative overflow-hidden ${mobileMenuOpen ? "hidden" : ""}`}>
          {/* <div className="flex flex-col justify-center items-center h-full gap-4">
          <IoDocumentLock className="text-[100px] text-primary"/>
          <p className="text-4xl font-bold text-primary">Access Denied!</p>
          <span className="text-gray-800 text-2xl">You do not have access to this page. Contact Support if you wish to have access.</span>
        </div> */}

          {breadcrumbs ? <Breadcrumbs title={breadcrumbs.title} parent={breadcrumbs.parent} currentPage={breadcrumbs.current} /> : null}
          {fluid ? <div className="flex gap-4">{children}</div> : children}

          {/* <p>Current State: {state}</p>
      {timeTillPrompt > 0 && (
        <p>
          {timeTillPrompt} {seconds} until prompt
        </p>
      )}
      {open ? <div className="flex flex-col justify-center items-center h-full gap-4">
          <IoDocumentLock className="text-[100px] text-primary"/>
          <p className="text-4xl font-bold text-primary">Are you still here?</p>
          <span className="text-gray-800 text-2xl">Logging out in {remaining} seconds</span>
          <Button title="I'm Still Here" type="primary" onClick={() => handleStillHere()}/>
        </div> : null} */}

          {/* <div
        className='modal'
        style={{
          display: open ? 'flex' : 'none'
        }}>
        <h3>Are you still here?</h3>
        <p>Logging out in {remaining} seconds</p>
        <Button title="I'm Still Here" type="primary" onClick={() => handleStillHere()}/>
      </div> */}
        </main>
      </div>
    </>
  );
}
