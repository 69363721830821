import { Navigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";

interface IProps {
  key?: number;
  element: any;
  path: any;
}

export const ProtectedRoute = ({ children }: any) => {
  const { profile } = useAppSelector((state: any) => state.auth);
  if (!profile) {
    return <Navigate to="/login" replace />;
  }
  return children;
};
