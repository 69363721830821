import { formatErrors, handleImalipayAPI } from "@redux/services/APIHandler";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


 type tokenState = {
    loading?: boolean;
    error?: any;
    accountno?: string | null,
    message?: string | null

}

const initialState: tokenState = {
  loading: false,
  error: [],
  accountno: null,
  message: null
};

// export const getAccountNo = handleImalipayAPI("search/accountno",  `/customers/0.0.1/zw/kyc/{mobile}/profile`, {method: "GET"})
  
export const getAccountNo =  createAsyncThunk("search/accountno", async (mobile: any, {rejectWithValue, getState}) => {
        const currentState: any = getState();
      const access_token = currentState.iptoken.accessToken;

    try {
      const rs = await axios.get(`${process.env.REACT_APP_IMALIPAY_DEV_LINK}customers/0.0.1/zw/kyc/${mobile}/profile`,
       {
        headers: { 
            "Authorization": `Bearer ${access_token}`,
            "Content-Type": "application/json"
         }
      })
      if (rs.data.statusCode === "9000") {
        return rejectWithValue(rs.data.errors)
      }
      return rs.data;
  
    } catch (e) {
 
      return rejectWithValue(e)
    }
  })


export const accountNumberSlice = createSlice({
    name: "token",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAccountNo.pending, (state) => {
            state.loading = true;
            state.error = [];
        });
        builder.addCase(getAccountNo.fulfilled, (state,{ payload}: any) => {
            state.loading = false;
            state.message = payload.statusMessage
            state.error = [];
            state.accountno = payload.data?.[0]?.accounts[0]?.accountNumber;
        });
        builder.addCase(getAccountNo.rejected, (state, {payload}: any) => {
            state.loading=false;
            state.error = formatErrors(payload)
        })
    }
})

export default accountNumberSlice.reducer