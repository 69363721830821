import React from "react";
import { Helmet } from "react-helmet-async";

interface IProps {
  title: string;
}
export default function PageTitle({ title }: IProps) {
  return (
    <Helmet>
      <title>AFX - {title}</title>
    </Helmet>
  );
}
